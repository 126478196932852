<template>
	<v-btn
		v-if="!mini"
		:block="fullWidth"
		:class="classes"
		:color="color"
		:disabled="disabled"
		:flat="flat"
		:href="href"
		:large="large"
		:loading="loading"
		:outline="outline"
		:round="round"
		:small="small"
		:target="target"
		v-bind="$attrs"
		@click="onClick"
	>
		<w-flex v-if="hasIcon" align-center row mr-1 shrink>
			<w-layout align-center row>
				<w-icon :color="iconColor" :large="large" class="px-1">{{ icon }}</w-icon>
				<slot></slot>
			</w-layout>
		</w-flex>
		<w-flex v-else :ma-auto="fullWidth" align-center row shrink>
			<w-layout row wrap>
				<slot></slot>
			</w-layout>
		</w-flex>
	</v-btn>
	<w-tooltip v-else-if="$slots.default" bottom>
		<template v-slot:activator="{ on }">
			<v-btn
				:class="classes"
				:color="color"
				:disabled="disabled"
				:flat="flat"
				:href="href"
				icon
				:large="large"
				:loading="loading"
				:outline="outline"
				:small="small"
				:target="target"
				v-bind="$attrs"
				v-on="on"
				@click="onClick"
			>
				<w-icon :color="iconColor" :large="large" :small="small">{{ icon }}</w-icon>
			</v-btn>
		</template>
		<span><slot></slot></span>
	</w-tooltip>
	<v-btn
		v-else
		:class="classes"
		:color="color"
		:disabled="disabled"
		:flat="flat"
		:href="href"
		icon
		:large="large"
		:loading="loading"
		:outline="outline"
		:small="small"
		:target="target"
		v-bind="$attrs"
		@click="onClick"
	>
		<w-icon :color="iconColor" :large="large" :small="small">{{ icon }}</w-icon>
	</v-btn>
</template>

<script>
/**
 * @displayName w-btn
 *
 * @since 0.3.1
 */
export default {
	name: 'WBtn',
	inheritAttrs: false,
	props: {
		color: {
			required: false,
			type: String,
			default: () => 'primary'
		},
		disabled: {
			required: false,
			type: Boolean,
			default: () => false
		},
		flat: {
			required: false,
			type: Boolean,
			default: () => false
		},
		fullWidth: {
			required: false,
			type: Boolean,
			default: () => false
		},
		href: {
			required: false,
			type: String,
			default: ''
		},
		icon: {
			required: false,
			type: String,
			default: ''
		},
		iconColor: {
			required: false,
			type: String,
			default: undefined
		},
		large: {
			default: false,
			required: false,
			type: Boolean
		},
		loading: {
			required: false,
			type: Boolean,
			default: () => false
		},
		mini: {
			required: false,
			type: Boolean,
			default: () => false
		},
		outline: {
			default: false,
			required: false,
			type: Boolean
		},
		round: {
			required: false,
			type: Boolean,
			default: true
		},
		small: {
			required: false,
			type: Boolean,
			default: () => false
		},
		target: {
			required: false,
			type: String,
			default: ''
		}
	},
	computed: {
		classes: function () {
			let classes = []

			if (this.$attrs.hasOwnProperty('mx-0')) {
				classes.push('mx-0')
			}
			if (this.$attrs.hasOwnProperty('px-0')) {
				classes.push('px-0')
			}

			return classes.join(' ')
		},
		hasIcon: function () {
			let result = true
			if (typeof this.icon != 'string' || this.icon.trim().length == 0) {
				result = false
			}
			return result
		}
	},
	methods: {
		onClick: function (event) {
			/**
			 * @event click
			 */
			this.$emit('click', event)
		}
	}
}
</script>
